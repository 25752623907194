let indexArrayByField = (data, field) => {
  let indexedArray = []
  for (let i = 0; i < data.length; i += 1) {
    indexedArray[data[i][field]] = data[i]
  }
  return indexedArray
}

// eslint-disable-next-line no-undef,no-unused-vars
let store = new Vuex.Store ({
  state : {
    runningQueries: 0,
    userInfos: { connected: false, cookie: {} },
    dataUpdated: 0,
    listeTickets: [],
    defaultLoc: [6.12000000000000100000, 48.77000000000001000000],
    modal: {
      visible: false,
      message: 'Message Modal !',
      title: 'Titre de la modale',
      okCallback: null,
      imageSrc: '',
      type: 'info',
      component: null
    },
    constantsLoaded: false,
    ssoChecked : false,
    constants : { },
    indexedConstants: {
      batiments: [],
      categories: [],
      sousCategories: [],
      surCategories: [],
      profils: [],
      origines: [],
      mods: []
    }
  },
  mutations: {
    incRunnnigQueries: (state) => { state.runningQueries ++ },
    decRunnnigQueries: (state) => { state.runningQueries -- },
    hideModal: (state) => { 
      state.modal.okCallback  = null
      state.modal.visible     = false
      state.modal.type        = 'info'
      state.modal.component   = null
    },
    setModal: (state, modal) => { 
      for (const [key, value] of Object.entries(modal)) {
        state.modal[key] = value
      }
    },
    showImageModal: (state, imageSrc) => {
      state.modal.type      = 'image'
      state.modal.visible   = true
      state.modal.isImage   = true
      state.modal.imageSrc  = imageSrc
    },
    setUserInfos: (state, userInfos) => {
      for (var key in userInfos) {
        if (userInfos.hasOwnProperty(key)) {
          state.userInfos[key] = userInfos[key]
        }
      }
      state.defaultLoc = [userInfos.longitude || 6.12000000000000100000, userInfos.latitude || 48.77000000000001000000]
      state.ssoChecked = true
    },
    setUserDelegue: (state, delegue) => {
      state.userInfos['delegue'] = delegue
    },
    setDataUpdated: (state) => { state.dataUpdated ++ },
    setConstants: (state, data) => {
      state.constants                         = data
      state.constants.sousCatParCat           = [];
      for (let i = 0; i < state.constants.categories.length; i += 1) {
        state.constants.sousCatParCat[state.constants.categories[i].num_categorie] = []
      }
      for (let i = 0; i < state.constants.sousCategories.length; i += 1) {
        state.constants.sousCatParCat[state.constants.sousCategories[i].num_categorie].push(state.constants.sousCategories[i])
      }

      state.constants.catParSurCat           = [];
      for (let i = 0; i < state.constants.surCategories.length; i += 1) {
        state.constants.catParSurCat[state.constants.surCategories[i].num_surCategorie] = []
      }
      for (let i = 0; i < state.constants.categories.length; i += 1) {
        state.constants.catParSurCat[state.constants.categories[i].num_surCategorie].push(state.constants.categories[i])
      }
      state.indexedConstants.batiments        = indexArrayByField(data.batiments, 'num_batiment')
      state.indexedConstants.categories       = indexArrayByField(data.categories, 'num_categorie')
      state.indexedConstants.sousCategories   = indexArrayByField(data.sousCategories, 'num_sousCategorie')
      state.indexedConstants.surCategories    = indexArrayByField(data.surCategories, 'num_surCategorie')
      state.indexedConstants.profils          = indexArrayByField(data.profils, 'num_profil')
      state.indexedConstants.origines         = indexArrayByField(data.origines, 'num_origine')
      state.indexedConstants.mods             = indexArrayByField(data.mods, 'num_mod')
      state.indexedConstants.lists            = indexArrayByField(data.lists, 'num_liste')
      state.indexedConstants.communes         = indexArrayByField(data.communes, 'num_commune')
      state.indexedConstants.priorites        = indexArrayByField(data.priorites, 'num_priorite')

      state.indexedConstants.listsItems       = []
      for (let i = 0; i < data.lists.length; i += 1) {
        for (let j = 0; j < data.lists[i].items.length; j += 1) {
          state.indexedConstants.listsItems[data.lists[i].items[j].num_item] = data.lists[i].items[j].item
        }
      }

      state.constantsLoaded                   = true
    },
    setListeTickets: (state, liste) => {
      state.listeTickets.splice(0, state.listeTickets.length)
      for (let i = 0; i < liste.length; i += 1) {
        state.listeTickets.push(liste[i])
      }
    },
    setSignalement: (state, signalement) => {
      for (let [key, value] of Object.entries(signalement)) {
        state.signalement[key] = value
      }
    }
  },
  actions: {
  }
})