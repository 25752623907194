let routes = [
  {   
    path: '/connexion/:backpath?',
    component: PAGES['connexion'], 
    name: 'pageConnexion' 
  },
  {   
    path: '/accueil',
    component: PAGES['accueil'], 
    name: 'pageAccueil' 
  },
  {   
    path: '/usager/:num_categorie?',
    component: PAGES['demandeInter'], 
    name: 'pageDemandeInter' 
  },
  {   
    path: '/delegation',
    component: PAGES['delegation'], 
    name: 'pageDelegation' 
  },
  { 
    path: '/ticket/resume/:uniqueId/:confirm?', 
    component: PAGES['formTicket'],
    name: 'pageResumeTicket' 
  },
  { 
    path: '/ticket/:identifiant', 
    component: PAGES['formTicket'],
    name: 'pageFormTicket' 
  },
  { 
    path: '/config', 
    component: PAGES['config'],
    name: 'pageConfig' 
  },
  { 
    path: '/configmodules', 
    component: PAGES['configModules'],
    name: 'pageConfigModules' 
  },
  { 
    path: '/configdisplays', 
    component: PAGES['configDisplays'],
    name: 'pageConfigDisplays' 
  },
  { 
    path: '/stats', 
    component: PAGES['stats'],
    name: 'pageStats' 
  },
  { 
    path: '/signalement/:num_categorie?', 
    component: PAGES['signalement'],
    name: 'pageSignalement' 
  },
  { 
    path: '*',
    redirect: '/accueil'
  }
]

let router = new VueRouter({ 
  routes: routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  let proceed = () => { if (store.state.constantsLoaded) { next() } }

  if (!store.state.constantsLoaded) {
    store.watch(
      (state) => state.constantsLoaded && state.ssoChecked,
      (value) => { if (value) { proceed() }}
    )
  } else {
    proceed()
  }
})

document.addEventListener("DOMContentLoaded", () => {
  
  new Vue({ 
    router,
    store,
    mounted: function () {
      this.server.get('/api/user/infos').then((data) => {
        store.commit('setUserInfos', data)
      }),
      this.server.get('/api/user/constants').then((data) => {
        store.commit('setConstants', data)
      })
    }
  }).$mount('#app')
})
